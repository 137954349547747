// Users.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

function Users() {
  console.log("Users component rendered");
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [entities, setEntities] = useState([]); // Ajouter l'état pour les entités disponibles
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    password: '',
    status: 'active',
    associatedEntities: [],
  });

  useEffect(() => {
    console.log("Users useEffect triggered");
    if (user && user.token) {
      fetchUsers(user.token);
      fetchEntities(user.token); // Récupérer les entités disponibles
    }
  }, [user]);

  const fetchUsers = async (token) => {
    console.log("Fetching users with token:", token);
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/users', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("API response:", response.data);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorMessage("Une erreur s'est produite lors de la récupération des utilisateurs.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntities = async (token) => {
    console.log("Fetching entities with token:", token);
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/entities', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Entities API response:", response.data);
      setEntities(response.data);
    } catch (error) {
      console.error("Error fetching entities:", error);
      setErrorMessage("Une erreur s'est produite lors de la récupération des entités.");
    }
  };

  const handleAddUser = async () => {
    try {
      await axios.post('https://rtinventory.cloud.backend.askgreg.fr/users', currentUser, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setAddUserModalOpen(false);
      fetchUsers();
    } catch (error) {
      console.error("Error adding user:", error);
      setErrorMessage("Erreur lors de l'ajout de l'utilisateur.");
    }
  };

  const handleEditUser = async () => {
    try {
      const userDataToSend = {
        ...currentUser,
        entities: currentUser.associatedEntities.map(entity => entity.id)
      };
      console.log("Sending update request with data:", userDataToSend);
      await axios.put(`https://rtinventory.cloud.backend.askgreg.fr/users/${currentUser.id}`, userDataToSend, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      setEditUserModalOpen(false);
      fetchUsers(user?.token);
    } catch (error) {
      console.error("Error updating user:", error);
      if (error.response && error.response.data && error.response.data.detail) {
        setErrorMessage(`Erreur lors de la mise à jour de l'utilisateur: ${error.response.data.detail}`);
      } else {
        setErrorMessage("Erreur lors de la mise à jour de l'utilisateur.");
      }
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`https://rtinventory.cloud.backend.askgreg.fr/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      setErrorMessage("Erreur lors de la suppression de l'utilisateur.");
    }
  };

  const openAddUserModal = () => {
    setCurrentUser({
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: '',
      status: 'active',
      associatedEntities: [],
    });
    setAddUserModalOpen(true);
  };

  const openEditUserModal = (user) => {
    setCurrentUser({
      ...user,
      status: user.status ? 'active' : 'inactive', // Assurez-vous que le statut est correctement défini
      associatedEntities: user.entities || [], // Assurez-vous que associatedEntities est toujours un tableau
      first_name: user.first_name || '', // Assurez-vous que first_name est toujours une chaîne
      last_name: user.last_name || '', // Assurez-vous que last_name est toujours une chaîne
      username: user.username || '', // Assurez-vous que username est toujours une chaîne
      email: user.email || '', // Assurez-vous que email est toujours une chaîne
      password: '', // Assurez-vous que password est toujours une chaîne
    });
    setEditUserModalOpen(true);
  };

  const closeAddUserModal = () => {
    setAddUserModalOpen(false);
  };

  const closeEditUserModal = () => {
    setEditUserModalOpen(false);
  };

  const handleToggleEntity = (entityId) => {
    setCurrentUser((prevUser) => {
      const isAssociated = prevUser.associatedEntities.some((entity) => entity.id === entityId);
      const updatedEntities = isAssociated
        ? prevUser.associatedEntities.filter((entity) => entity.id !== entityId)
        : [...prevUser.associatedEntities, entities.find((entity) => entity.id === entityId)];
      return {
        ...prevUser,
        associatedEntities: updatedEntities,
      };
    });
  };

  return (
    <div>
      <h1 className="title">Users</h1>
      <h2 className="subtitle">Manage users and their associated entities</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">First Name</th>
            <th className="has-text-left is-size-7">Last Name</th>
            <th className="has-text-left is-size-7">Username</th>
            <th className="has-text-left is-size-7">Email</th>
            <th className="has-text-left is-size-7">Status</th>
            <th className="has-text-left is-size-7">Associated Entities</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="has-text-left is-size-7">{user.first_name}</td>
              <td className="has-text-left is-size-7">{user.last_name}</td>
              <td className="has-text-left is-size-7">{user.username}</td>
              <td className="has-text-left is-size-7">{user.email}</td>
              <td className="has-text-left is-size-7">{user.status ? 'Active' : 'Inactive'}</td>
              <td className="has-text-left is-size-7">
                {user.entities.map((entity) => (
                  <div key={entity.id}>{entity.name}</div>
                ))}
              </td>
              <td className="has-text-right">
                <button className="button is-primary is-light is-small" onClick={() => openEditUserModal(user)}>
                  <span className="icon is-small">
                    <i className="fas fa-edit"></i> 
                  </span>
                  <span>Edit</span>
                </button>
                <button className="button is-danger is-light is-small" onClick={() => handleDeleteUser(user.id)}>
                  <span className="icon is-small">
                    <i className="fas fa-trash-alt"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="button is-primary is-light is-small" onClick={openAddUserModal}>
        <i className="fas fa-plus"></i> Add User
      </button>

      {/* Modal pour ajouter un utilisateur */}
      <div className={`modal ${addUserModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeAddUserModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add User</p>
            <button className="delete" aria-label="close" onClick={closeAddUserModal}></button>
          </header>
          <section className="modal-card-body">
            <div className="field mb-3">
              <label className="label">First Name</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.first_name} onChange={(e) => setCurrentUser({ ...currentUser, first_name: e.target.value })} required />
              </div>
            </div>
            <div className="field mb-3">
              <label className="label">Last Name</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.last_name} onChange={(e) => setCurrentUser({ ...currentUser, last_name: e.target.value })} required />
              </div>
            </div>
            <div className="field mb-3">
              <label className="label">Username</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.username} onChange={(e) => setCurrentUser({ ...currentUser, username: e.target.value })} required />
              </div>
            </div>
            <div className="field mb-3">
              <label className="label">Email</label>
              <div className="control">
                <input className="input" type="email" value={currentUser.email} onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })} required />
              </div>
            </div>
            <div className="field mb-3">
              <label className="label">Password</label>
              <div className="control">
                <input className="input" type="password" value={currentUser.password} onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })} required />
              </div>
            </div>
            <div className="field mb-3">
              <label className="label">Status</label>
              <div className="control">
                <label className="radio">
                  <input type="radio" value="active" checked={currentUser.status === 'active'} onChange={(e) => setCurrentUser({ ...currentUser, status: e.target.value })} />
                  Active
                </label>
                <label className="radio">
                  <input type="radio" value="inactive" checked={currentUser.status === 'inactive'} onChange={(e) => setCurrentUser({ ...currentUser, status: e.target.value })} />
                  Inactive
                </label>
              </div>
            </div>
            <div className="field">
              <label className="label">Associated Entities</label>
              <div className="control">
                {entities.map((entity) => (
                  <label key={entity.id} className="checkbox">
                    <input
                      type="checkbox"
                      checked={currentUser.associatedEntities.some((e) => e.id === entity.id)}
                      onChange={() => handleToggleEntity(entity.id)}
                    />
                    {entity.name}
                  </label>
                ))}
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleAddUser}>
              <span className="icon">
                <i className="fas fa-check"></i>
              </span>
              <span>Submit</span>
            </button>
            <button className="button is-danger is-light is-small" onClick={closeAddUserModal}>
              <span className="icon">
                <i className="fas fa-times"></i>
              </span>
              <span>Cancel</span>
            </button>
          </footer>
        </div>
      </div>

      {/* Modal pour éditer un utilisateur */}
      <div className={`modal ${editUserModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeEditUserModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit User</p>
            <button className="delete" aria-label="close" onClick={closeEditUserModal}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">First Name</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.first_name} onChange={(e) => setCurrentUser({ ...currentUser, first_name: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Last Name</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.last_name} onChange={(e) => setCurrentUser({ ...currentUser, last_name: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Username</label>
              <div className="control">
                <input className="input" type="text" value={currentUser.username} onChange={(e) => setCurrentUser({ ...currentUser, username: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input className="input" type="email" value={currentUser.email} onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Status</label>
              <div className="control">
                <label className="radio">
                  <input type="radio" value="active" checked={currentUser.status === 'active'} onChange={(e) => setCurrentUser({ ...currentUser, status: e.target.value })} />
                  Active
                </label>
                <label className="radio">
                  <input type="radio" value="inactive" checked={currentUser.status === 'inactive'} onChange={(e) => setCurrentUser({ ...currentUser, status: e.target.value })} />
                  Inactive
                </label>
              </div>
            </div>
            <div className="field">
              <label className="label">Associated Entities</label>
              <div className="control">
                <div className="columns is-multiline">
                  {entities.map((entity) => (
                    <div key={entity.id} className="column is-one-third">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={currentUser.associatedEntities.some((e) => e.id === entity.id)}
                          onChange={() => handleToggleEntity(entity.id)}
                        />
                        <span className="has-text-weight-normal">{entity.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleEditUser}>
              <span className="icon">
                <i className="fas fa-check"></i>
              </span>
              <span>Submit</span>
            </button>
            <button className="button is-danger is-light is-small" onClick={closeEditUserModal}>
              <span className="icon">
                <i className="fas fa-times"></i>
              </span>
              <span>Cancel</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Users;